<template>
    <a-layout style="min-height:100%">
      <a-layout-header :class="['layout-header', 'has-tabs']">
        <PublicHeader></PublicHeader>
      </a-layout-header>
      <a-layout-content>
        <div class="content">
          <router-view />
        </div>
      </a-layout-content>
      <a-layout-footer style="background: #333333;">
        <PublicFooter />
      </a-layout-footer>
    </a-layout>
  <!-- <a-layout id="app-layout" :class="[isMobile && 'layout-mobile', appTheme]"> -->
    <!-- <div v-if="isMobile" class="layout-mobile-mask" @click="closeSideBar" /> -->
    <!-- <a-layout-sider
      v-if="!isHeaderMenu"
      :class="[isLightSider && 'ant-layout-sider-light']"
    >
      <Logo></Logo>
      <Menu></Menu>
    </a-layout-sider> -->
  <!-- </a-layout> -->
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { Layout } from "ant-design-vue";
import { useStore } from "vuex";
import Menu from "./pritails/menu/Index";
import Logo from "./pritails/logo/index.vue";
import PublicFooter from './components/PublicFooter.vue'
import PublicHeader from './components/PublicHeader.vue'
import "../../styles/public.less";

export default defineComponent({
  components: {
    ALayout: Layout,
    ALayoutSider: Layout.Sider,
    ALayoutHeader: Layout.Header,
    ALayoutContent: Layout.Content,
    ALayoutFooter: Layout.Footer,
    Menu,
    Logo,
    PublicFooter,
    PublicHeader,
  },
  setup() {
    const { getters, commit } = useStore();

    const isMobile = computed(() => getters.isMobile);
    const appTheme = computed(() => getters.theme);
    const isLightSider = computed(() => getters.theme === "theme-light");
    const isHeaderMenu = computed(
      () => getters.layout === "layout-head" && !getters.isMobile
    );

    const onResize = () => {
      const { width } = document.body.getBoundingClientRect();
      const isLayoutMobile = width !== 0 && width - 1 < 576;
      commit("app/UPDATE_ISMOBILE", isLayoutMobile);

      if (isLayoutMobile) {
        setTimeout(() => {
          if (getters.isMobile && !getters.collapsed) {
            commit("app/TOGGLE_COLLAPSED");
          }
        }, 500);
      }
    };

    onResize();
    window.addEventListener("resize", onResize);

    return {
      isMobile,
      appTheme,
      isLightSider,
      isHeaderMenu,
    };
  },
});
</script>

<style lang="less" scoped>
.public {
  height: 100vh;
}

.content {
  height: 100%;
  overflow: auto;
  background: #fff;
}
</style>