<template>
  <div class="content-width">
    <div style="padding: 0 16px; display: flex; justify-content: space-between; flex-wrap:wrap;">
      <div class="logo">
        <img src="@/assets/image/public/logo-4.png" alt style="width: 280px" />
      </div>
      <div style="flex: 1; width:100%; min-width: 120px;" class="public-header-menu">
        <a-menu
          mode="horizontal"
          v-model:selectedKeys="selectedKeys"
          class="defined-color"
          style="border-bottom: none"
          @click="handleNavigate"
        >
          <a-menu-item v-for="item in routerData" :key="item.name" class="public-header-menu-item">{{
            $t(item.title)
          }}</a-menu-item>
        </a-menu>
      </div>
      <div style>
        <a-space :size="30">
          <router-link to="/login">{{ $t("common.login") }}</router-link>
          <router-link to="/register">{{ $t("public.register") }}</router-link>
          <!-- <LangChooser>
            <a href="javascript:;">{{choosedLang}}</a>
          </LangChooser> -->
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  computed
} from "vue";
import { Row, Col, Space, Menu } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import LangChooser from '../../components/LangChooser.vue'
import { useStore } from "vuex";

export default defineComponent({
  name: "public_header",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AMenuSubMenu: Menu.SubMenu,
    LangChooser,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const vueI18n = useI18n();
    const { getters } = useStore()

    const state = reactive({
      selectedKeys: ["public_home"],
    });

    const routerData = ref([
      {
        title: "public.home_page",
        name: "public_home",
      },
      {
        title: "public.se_logistics",
        name: "public_se_logistics",
      },
      {
        title: "public.se_overseas_warehouse",
        name: "public_se_warehouse",
      },
      // {
      //   title: "public.se_finance",
      //   name: "public_se_finance",
      // },
      {
        title: "public.about_us",
        name: "public_about_us",
      },
    ]);

    const handleNavigate = (item) => {
      let name = item.key
      router.push({ name })
    }

    watch(() => route.fullPath, (newFullPath) => {
      state.selectedKeys = [route.name]
    }, { immediate: true });
    const choosedLang = computed(()=>{
      return (getters.language||"zh-CN").toUpperCase();
    })
    return {
      ...toRefs(state),
      routerData,
      handleNavigate,
      choosedLang,
    };
  },
});
</script>

<style lang="less" >

.logo {
  float: left;
  width:280px;
  max-width:100%;
  // height: 31px;
  background: rgba(255, 255, 255, 0.3);
  img{
    max-width:100%;
  }
}
.public-header-menu{
  .ant-menu-item:hover,
  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-open,
  .ant-menu-submenu-active{
    color: orange!important;
  }
  
  // .ant-menu-item-selected,.ant-menu-item:active, .ant-menu-submenu-title:active{
  //   background-color: #ffedc5!important;
  // }
  .ant-menu-item:hover:after,
  .ant-menu-item-selected:after,
  .ant-menu-item-active:after,
  .ant-menu-submenu-selected:after,
  .ant-menu-submenu-open:after,
  .ant-menu-submenu-active:after
  {
    border-color: orange!important;
  }
}
:not(.ant-menu-horizontal) .public-header-menu-item.ant-menu-item:hover,
.public-header-menu-item.ant-menu-item:hover,
.public-header-menu-item.ant-menu-item-selected
{
    color: orange!important;
}

// .defined-color.ant-menu-horizontal > .ant-menu-item::after {
//   transition: none;
// }

// .defined-color.ant-menu-horizontal:not(.ant-menu-dark)
//   > .ant-menu-item-selected,
//   .defined-color.ant-menu-horizontal:not(.ant-menu-dark)
//   > .ant-menu-submenu-selected {
//   color: orange;
// }

// .defined-color.ant-menu-horizontal:not(.ant-menu-dark)
//   > .ant-menu-item-selected::after,
//   .defined-color.ant-menu-horizontal:not(.ant-menu-dark)
//   > .ant-menu-submenu-selected::after  {
//   border-color: orange;
//   //border-bottom: none;
// }

// .defined-color.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
//   color: orange;
// }

// .defined-color.ant-menu-horizontal:not(.ant-menu-dark)
//   > .ant-menu-item-active::after {
//   border-color: orange;
//   // border-bottom: none;
// }
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
// {
//   background-color: #ffeadb;
// }


// .defined-color.ant-menu-horizontal:not(.ant-menu-dark)
//   > .ant-menu-item-over::before {
//   border-bottom: none;
// }
</style>