<template>
  <div class="content-width">
    <a-row style="margin-top: 30px;">
      <a-col :span="8" :xs="24" :md="8" class="pr-md-3">
        <img
          src="@/assets/image/public/logo-6.png"
          alt=""
          style="width: 320px;max-width:100%;"
          class="mb-3"
        />
      </a-col>
      <a-col :span="16" :xs="24" :md="16">
        <a-row :gutter="4" justify="space-between">
          <a-col
            v-for="(item, index) in links"
            :key="index"
            class="mb-3"
            :xs="12"
            :sm="8"
            :md="6"
          >
            <a-space direction="vertical" :size="6">
              <h3 style="color: #fff; font-weight: bold" class="mb-0">
                {{ $t(item.title) }}
              </h3>
              <template
                v-for="ite in item.child"
                :key="ite.title">
              <router-link v-if="!ite.link"
                style="color: #fff"
                :to="ite.router"
                >{{ $t(ite.title) }}</router-link
              >
              <a v-else
               :target="ite.target"
                style="color: #fff"
                 :href="ite.link"
                 >{{ $t(ite.title) }}</a>
              </template>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-space direction="vertical" style="margin-top: 30px">
      <span class="text-white"
        >{{ $t("public.business_cooperation") }}:
        <a class="text-white" :href="'mailto:' + email">{{ email }}</a></span
      >
      <span class="text-white"
        >Copyright 2022 Saleasy.com All Rights Reserved</span
      >
      <a href="https://beian.miit.gov.cn/" target="_blank" class="text-white"
        >粤ICP备2022035993号-1</a
      >
    </a-space>
  </div>
</template>

<script>
import { Row, Col, Space } from "ant-design-vue";
import { defineComponent, reactive, ref, toRefs } from "vue";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "public_footer",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      email: "cooperation@saleasy.com",
    });

    const links = ref([
      {
        title: "public.product_service",
        child: [
          {
            title: "public.se_logistics",
            router: {
              path: "/selogistics",
            },
          },
          {
            title: "public.se_overseas_warehouse",
            router: {
              path: "/sewarehouse",
            },
          },
          // {
          //   title: "public.se_finance",
          //   router: {
          //     path: "",
          //   },
          // },
        ],
      },
      {
        title: "public.law",
        child: [
          {
            title: "public.privacy_policy",
            router: {
              path: "/privacy",
            },
          },
          // {
          //   title: "public.terms_of_use",
          //   router: {
          //     path: "",
          //   },
          // },
        ],
      },
      // {
      //   title: "public.developer_documentation",
      //   child: [
      //     {
      //       title: "public.API_technical_documentation",
      //       router: {
      //         path: "",
      //       },
      //     },
      //   ],
      // },
      {
        title: "public.cooperation",
        child: [
          {
            title: "public.partner_program",
            router: {
              path: "",
            },
            link:process.env.VUE_APP_PERTNERURL,
          },
           {
            title: "public.open_platform",
            router: {
              path: "",
            },
            link:process.env.VUE_APP_OPENDOCURL,
            target:"_blank"
          },
          // {
          //   title: "public.business_cooperation",
          //   router: {
          //     path: "",
          //   },
          // },
        ],
      },
    ]);
    return {
      ...toRefs(state),
      links,
    };
  },
});
</script>

<style lang="less" scoped>
.text-white {
  color: white !important;
}
</style>